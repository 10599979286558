<template>
  <v-tooltip v-model="show" :disabled="!showTooltips || isManualDisabled || !text" v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-inline">
        <slot v-if="!isClickable"></slot>
        <v-btn v-else @click="show = !show" text icon>
          <slot></slot>
        </v-btn>
      </div>
    </template>
    <slot v-if="$slots.htmlContent" name="htmlContent"></slot>
    <div v-else-if="text" v-html="text"></div>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HelpTooltip',

  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isManualDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    show: false,
  }),

  computed: {
    ...mapState('common', ['showTooltips']),
  },
}
</script>
